import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetDetailsQuery } from '../api/apiSlice';
import { TeamLogo } from '../hero/TeamLogo';

type FullHeaderProps = {
  showLogo?: boolean;
}

export function FullHeader({ showLogo = true }: FullHeaderProps): JSX.Element {
  const { data, isLoading } = useGetDetailsQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{
      textAlign: 'center',
      marginBottom: showLogo ? {
        sm: '70px',
        xs: '50px',
      } : 0,
      pb: showLogo ? 0 : 2,
    }}
    >
      <>
        <Typography
          color="white"
          variant="h5"
          sx={{
            fontWeight: 'bold', textTransform: 'uppercase',
          }}
        >
          {data?.title}
        </Typography>
        <Typography
          color="white"
          variant="h5"
          sx={{ textTransform: 'capitalize' }}
        >
          {data?.subtitle}
        </Typography>
      </>
      {showLogo && (
        <Box sx={{
          textAlign: 'center',
          height: {
            sm: '140px',
            xs: '100px',
          },
          marginTop: {
            sm: '-55px',
            xs: '-35px',
          },
          transform: 'translateY(50%)',
        }}
        >
          <TeamLogo />
        </Box>
      )}
    </Box>
  );
}
