/* eslint-disable class-methods-use-this */
class Social {
  canCopy(): boolean {
    return !!navigator && !!navigator.clipboard;
  }

  hasShareOptions(): boolean {
    return !!navigator && !!navigator.share;
  }

  shareOptions(link: string, title: string): void {
    navigator.share({
      title: document.title,
      text: title,
      url: link,
    });
  }

  copy(link: string): boolean {
    if (!this.canCopy()) {
      return false;
    }
    navigator.clipboard.writeText(link);
    return true;
  }

  tweet(link: string, title: string): void {
    // eslint-disable-next-line max-len
    const tweetLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(`${link}?via=tweet`)}&text=${encodeURIComponent(`Help ${title}`)}&via=tweet`;
    window.open(tweetLink, '_blank');
  }

  postFacebook(link: string): void {
    let y = 0;
    let x = 0;
    const w = 600;
    const h = 600;
    if (window.top) {
      y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
      x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
    }

    const fblink = 'https://www.facebook.com/dialog/share?'
      + `app_id=${window.FACEBOOK_APP_ID}`
      + '&display=popup'
      + `&href=${encodeURIComponent(`${link}?via=fb`)}`
      + `&redirect_uri=${encodeURIComponent(link)}`;
    const params = `status=no,toolbar=no,menubar=no,width=600,height=600,noopener=yes,top=${y},left=${x},copyhistory=no,directories=no`;
    window.open(fblink, '_blank', params);
  }
}

export const social = new Social();
