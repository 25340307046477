/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { eventEmitter, EventType } from 'helpers/EventEmitter';
import { User, UserRole } from '../features/user/domain';
import {
  useLoginMutation, useLogoutMutation, useLazyGetUserQuery, useRefreshAntiForgeryTokenMutation
} from '../features/api/apiSlice';
import { getBasename } from '../utils';
import { redirectHelper } from '../helpers/RedirectHelper';

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: (userName: string, password: string) => Promise<boolean>;
  refresh: () => Promise<void>;
  logout: () => Promise<void>;
}

const guest: User = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  roleId: '',
  roleName: UserRole.Guest,
  id: '',
  uniqueCode: '',
  isSameSite: true,
};

type LoginError = null | undefined | {
  [key: string]: string[]
}

const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);

export function AuthProvider({
  children,
}: {
    children: ReactNode;
  }): JSX.Element {
  const [user, setUser] = useState<User>(guest);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LoginError>(undefined);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [doLogin] = useLoginMutation();
  const [doLogOut] = useLogoutMutation();
  const [getUser] = useLazyGetUserQuery();
  const [refreshAntiForgeryToken] = useRefreshAntiForgeryTokenMutation();

  useEffect(() => {
    if (error) setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  async function logout(): Promise<void> {
    await doLogOut();
    setUser(guest);
    await refreshAntiForgeryToken().unwrap();
  }

  useEffect(() => {
    if (getBasename() !== '/') {
      getUser()
        .unwrap()
        .then(async (currentUser: User) => {
          if (currentUser.isSameSite) {
            setUser(currentUser);
          } else {
            await logout();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_error: any) => { /* skip */ })
        .finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function login(userName: string, password: string): Promise<boolean> {
    setLoading(true);

    try {
      const loggedUser = await doLogin({
        userName,
        password,
        rememberMe: true,
      }).unwrap();
      setUser(loggedUser);
      navigate(redirectHelper.getAfterLogInRedirectUrl(loggedUser.roleName));
      return true;
    } catch (e) {
      setError((e as { data: LoginError }).data);
      return false;
    } finally {
      setLoading(false);
    }
  }

  async function refresh(): Promise<void> {
    setLoading(true);
    try {
      const currentUser = await getUser().unwrap();
      setUser(currentUser);
    } catch (e) {
      setError((e as { data: LoginError }).data);
    } finally {
      setLoading(false);
    }
  }

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      refresh,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error]
  );

  useEffect(() => {
    eventEmitter.subscribe(EventType.LOGOUT, '', () => setUser(guest));
    return () => {
      eventEmitter.unsubscribe(EventType.LOGOUT, '');
    };
  }, [setUser]);

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
