import { useParams, Outlet } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalError } from 'features/layout/GlobalError';
import ResponsiveAppBar from 'features/header/ResponsiveAppBar';
import { Footer } from 'features/footer/Footer';
import { FullHeader } from 'features/header/FullHeader';
import { useGetDetailsQuery } from 'features/api/apiSlice';

export function InnerLayout(): JSX.Element {
  const { id, publicId } = useParams();
  const {
    isError, error,
    isLoading,
  } = useGetDetailsQuery();

  if (isError) {
    return <GlobalError code={'status' in error && error.status ? +error.status : undefined} />;
  }

  return (
    <>
      {isLoading && (
        <Box sx={{
          textAlign: 'center', width: '100%', p: 11, backgroundColor: '#211f20',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Box sx={{ backgroundColor: '#211f20', position: 'relative' }}>
          <ResponsiveAppBar donorId={id ? +(id || 0) : 0} donorPublicId={publicId} />
          <FullHeader showLogo={false} />
        </Box>
      )}
      <Container maxWidth="lg" sx={{ paddingTop: 3 }}>
        <Grid container direction="column">
          <Grid md>
            <Outlet />
          </Grid>
          <Grid xs={12} sx={{ color: 'grey.400', mt: '3em' }} textAlign="center">
            <Footer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
