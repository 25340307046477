import { useState } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { social } from '../../helpers/Social';
import { useGetDetailsQuery } from '../api/apiSlice';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      // eslint-disable-next-line max-len
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '6px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 14px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface ShareProps extends MenuProps {
  onCloseItem: () => void;
}

export function Share({ onCloseItem, ...props }: ShareProps): JSX.Element {
  const [copied, setCopied] = useState(false);
  const { data } = useGetDetailsQuery();
  const canCopy = social.canCopy();
  const hasShareOptions = social.hasShareOptions();
  const shareLink = data?.shareLink || '';
  const copy = (): void => {
    if (shareLink && social.copy(shareLink)) {
      setCopied(true);
    }
  };

  const tweet = (): void => {
    if (shareLink) {
      social.tweet(shareLink, data?.title || '');
    }
    if (onCloseItem) {
      onCloseItem();
    }
  };

  const postFacebook = (): void => {
    if (shareLink) {
      social.postFacebook(shareLink);
    }
    if (onCloseItem) {
      onCloseItem();
    }
  };

  const shareOptions = (): void => {
    if (shareLink && hasShareOptions) {
      social.shareOptions(shareLink, data?.title || '');
    }
    if (onCloseItem) {
      onCloseItem();
    }
  };

  const linkText = copied ? 'Link Copied!' : 'Copy Link';

  return (
    <StyledMenu {...props}>
      <MenuItem onClick={postFacebook} disableRipple>
        <FacebookIcon />
        Facebook
      </MenuItem>
      <MenuItem onClick={tweet} disableRipple>
        <TwitterIcon />
        Twitter
      </MenuItem>
      {canCopy && (
        <MenuItem onClick={copy} disableRipple>
          <ContentCopyIcon />
          {linkText}
        </MenuItem>
      )}
      {hasShareOptions && (
        <MenuItem onClick={shareOptions} disableRipple>
          <MoreVertIcon />
          More Options
        </MenuItem>
      )}
    </StyledMenu>
  );
}
