import { styled } from '@mui/material/styles';
import { useGetDetailsQuery } from '../api/apiSlice';

const StyledImg = styled('img')(({ theme }) => ({
  borderWidth: '0.2em',
  borderStyle: 'solid',
  maxHeight: '100%',
  [theme.breakpoints.only('xs')]: {
    borderWidth: '0.1em',
  },
}));

export function TeamLogo(): JSX.Element {
  const { data } = useGetDetailsQuery();

  const borderColor = data?.logoBorderColor ? `#${data.logoBorderColor}` : '#211f20';

  return (
    <StyledImg
      src={data?.logo || '/images/icon.png'}
      alt="Team Logo"
      sx={{
        borderColor,
      }}
    />
  );
}
