import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import AlertTitle from '@mui/material/AlertTitle';

export function GlobalError({ code = 404 }: { code?: number }): JSX.Element {
  return (
    <Alert
      variant="standard"
      sx={{
        justifyContent: 'center',
        mt: '2em',
        fontSize: '1.25em',
        '& svg': {
          width: '1.25em',
          height: '1.25em',
        },
      }}
      severity="error"
    >
      <AlertTitle sx={{ fontSize: '1em' }}>
        {`${code} - Not Available`}
      </AlertTitle>
      This fundraiser is completed or not found. Please check the URL or try again later.
      <br />
      <Link href="/">Home</Link>
    </Alert>
  );
}
