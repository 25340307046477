import { Path } from 'react-router';
import { UserRole } from '../user/domain';

type Page = {
  type: string;
  label: string;
  url: Path | string;
  roles?: UserRole[];
};

export const navStore: {[key: string]: Page[]} = {
  main: [{
    type: 'donate',
    label: 'Donate',
    url: '',
  }, {
    type: 'home',
    label: 'Home',
    url: '/',
  }, {
    type: 'admin',
    label: 'Admin',
    url: '/admin/',
    roles: [UserRole.Admin],
  }, {
    type: 'admin',
    label: 'Admin',
    url: '/manage/',
    roles: [UserRole.SalesRep],
  }, {
    type: 'live',
    label: 'LiveTracking',
    url: '/Fundraiser/Live',
    roles: [UserRole.Admin, UserRole.Coach],
  }, {
    type: 'start',
    label: 'Start a Fundraiser',
    url: '/start/',
    roles: [UserRole.Unused],
  }, {
    type: 'share',
    label: 'Share',
    url: '',
  }, {
    type: 'login',
    label: 'Log In',
    url: '/Account/Login',
    roles: [UserRole.Guest],
  }, {
    type: 'sfl',
    label: 'SFL',
    url: '/Coach',
    roles: [UserRole.Coach, UserRole.Admin],
  }, {
    type: 'contacts',
    label: 'My Contacts',
    url: '/Account/Contact',
    roles: [UserRole.Player],
  }, {
    type: 'donations',
    label: 'My Donations',
    url: '/Account/Donation',
    roles: [UserRole.Player],
  }, {
    type: 'join',
    label: 'Join',
    url: '/Account/Join',
    roles: [UserRole.Guest],
  }],
  user: [{
    type: 'profile',
    label: 'Profile',
    url: '/Account/Manage',
    roles: [UserRole.Player, UserRole.Coach, UserRole.Admin],
  }, {
    type: 'logout',
    label: 'Logout',
    url: '',
    roles: [UserRole.Player, UserRole.Coach, UserRole.Admin],
  }],
  guest: [{
    type: 'donate',
    label: 'Donate',
    url: '',
  }],
};
