export enum UserRole {
  Admin = 'Admin',
  Coach = 'Coach',
  Player = 'Player',
  Guest = 'Guest',
  Unused = 'Unused',
  SalesRep = 'SalesRep'
}

export type User = {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  roleId: string;
  roleName: UserRole;
  phoneNumber: string;
  uniqueCode: string;
  isSameSite: boolean;
}
