import React, { Suspense } from 'react';
import Loader from './Loader';

interface GenericFunctionType {
  <T extends JSX.IntrinsicAttributes, >(ComponentToWrap: React.ComponentType & JSX.IntrinsicAttributes): React.ComponentType<T>
}

const Loadable: GenericFunctionType = (ComponentToWrap: React.ComponentType & JSX.IntrinsicAttributes) => (
  function LoaderSuspence(props): JSX.Element {
    return (
      <Suspense fallback={<Loader />}>
        <ComponentToWrap {...props} />
      </Suspense>
    );
  }
);

export default Loadable;
