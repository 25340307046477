import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import {
  CoachContact,
  CoachContactState,
  CoachDonation,
  CoachDonationSummary,
  CoachFilter,
  CoachPlayerContact,
  CoachPlayerContactSummary,
  CoachPledge,
  CoachPledgeSummary,
  CoachTeam,
  CoachUpdatePledgeRequest,
  DonationMethodSummary,
  DonationStatusSummary,
  ReconcileCheck,
  SaveCoachPlayerContact,
  UnconfirmedDonation,
  UpdateEmail
} from 'features/coach/domain';
import { eventEmitter, EventType } from 'helpers/EventEmitter';
import { DonationRequest } from '../../helpers/PaymentHelper';
import { getBasename } from '../../utils';
import {
  ChangePassword, ForgotPasswordRequest, Profile, TextCode, SignUp, Photo
} from '../account/domain';
import {
  AddMessage, AddMessageResult, Amount, DonationResult
} from '../donate/domain';
import { Fan } from '../fan/domain';
import {
  FundraiserDetails,
  SubscriptionSettings,
  SubscriptionSettingsEntity,
  SubscriptionSettingsId
} from '../fundraiser/domain';
import { Pledge } from '../home/domain';
import { LiveBoardFilter, PledgeBoard, LiveDashboard } from '../liveboard/domain';
import { Media } from '../media/domain';
import { Message } from '../message/domain';
import {
  SavePlayerContact,
  PlayerContact, PlayerDonation, PlayerDonationSummary, PlayerNameToJoin, Player, RenewedContactState
} from '../player/domain';
import { User } from '../user/domain';
import { GenericResult } from './domain';

const getCsrfToken = (): string => document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1') || '';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers) => {
    headers.set('X-Requested-With', 'XMLHttpRequest');
    const token = getCsrfToken();
    if (token) {
      headers.set('X-XSRF-TOKEN', token);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error
    && result.error.status === 401
    && !['getCurrentPlayer', 'getUser'].includes(api.endpoint)) {
    eventEmitter.dispatch(EventType.LOGOUT, '');
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Fan',
    'Message',
    'Detail',
    'Player',
    'User',
    'PlayerContact',
    'PlayerDonation',
    'UNAUTHORIZED',
    'UnconfirmedDonations',
    'CoachSummary',
    'CoachDonation',
    'CoachPledge',
    'CoachContact',
  ],
  endpoints: (builder) => ({
    getEnvAlias: builder.query<string, string>({
      query: (code) => ({ url: 'Env/Alias', params: { code } }),
    }),
    refreshAntiForgeryToken: builder.mutation<boolean, void>({
      query() {
        return {
          url: '/Env/RefreshAntiForgeryToken',
          method: 'PUT',
        };
      },
    }),
    getMessages: builder.query<Message[], void>({
      query: () => (`${getBasename()}/Fundraiser/Message`),
      providesTags: ['Message'],
    }),
    getFans: builder.query<Fan[], void>({
      query: () => (`${getBasename()}/Fundraiser/Fan`),
      providesTags: ['Fan'],
    }),
    getDetails: builder.query<FundraiserDetails, void>({
      query: () => (`${getBasename()}/Fundraiser/Detail`),
      providesTags: ['Detail'],
    }),
    getMedia: builder.query<Media[], void>({
      query: () => (`${getBasename()}/Fundraiser/Media`),
    }),
    getDonor: builder.query<Pledge, { id: number, publicId: string }>({
      query: ({ id, publicId }) => (`${getBasename()}/Fundraiser/Donor/${id}?PublicId=${publicId}`),
      providesTags: ['Player'],
    }),
    getPlayer: builder.query<Pledge, number>({
      query: (id) => (`${getBasename()}/Fundraiser/Player/${id}`),
      providesTags: ['Player'],
    }),
    getCurrentPlayer: builder.query<Player, void>({
      query: () => (`${getBasename()}/Fundraiser/CurrentPlayer`),
      providesTags: (_, error) => error?.status === 401 || error?.status === 403 ? ['UNAUTHORIZED'] : ['Player'],
    }),
    getPlayerContacts: builder.query<PlayerContact[], void>({
      query: () => (`${getBasename()}/PlayerContact/Index`),
      providesTags: ['PlayerContact'],
    }),
    getPlayerContactsToRemind: builder.query<PlayerContact[], void>({
      query: () => (`${getBasename()}/PlayerContact/Remind`),
      providesTags: ['PlayerContact'],
    }),
    getPlayerContact: builder.query<PlayerContact, number>({
      query: (id) => (`${getBasename()}/PlayerContact/Contact/${id}`),
      providesTags: ['PlayerContact'],
    }),
    getPlayerRenewedState: builder.query<RenewedContactState, void>({
      query: () => (`${getBasename()}/PlayerContact/RenewedState`),
      providesTags: ['Player'],
    }),
    getPlayerDonations: builder.query<PlayerDonation[], void>({
      query: () => (`${getBasename()}/PlayerDonation/Index`),
      providesTags: ['PlayerDonation'],
    }),
    getPlayerSummary: builder.query<PlayerDonationSummary, void>({
      query: () => (`${getBasename()}/PlayerDonation/Summary`),
      providesTags: ['PlayerDonation', 'PlayerContact'],
    }),
    getPlayersNames: builder.query<PlayerNameToJoin[], void>({
      query: () => (`${getBasename()}/Fundraiser/PlayersNames`),
    }),
    getAmounts: builder.query<Amount[], void>({
      query: () => (`${getBasename()}/Fundraiser/Amounts`),
    }),
    getUser: builder.query<User, void>({
      query: () => (`${getBasename()}/Account/CurrentUser`),
      providesTags: (_, error) => error?.status === 401 || error?.status === 403 ? ['UNAUTHORIZED'] : ['User'],
    }),
    getBoardScores: builder.query<PledgeBoard[], LiveBoardFilter>({
      query(body) {
        return {
          url: `${getBasename()}/Live/TrackingData`,
          method: 'POST',
          body,
        };
      },
    }),
    getLiveDashboard: builder.query<LiveDashboard, void>({
      query(body) {
        return {
          url: `${getBasename()}/Live/Dashboard`,
          method: 'POST',
          body,
        };
      },
    }),
    codeValidity: builder.mutation<boolean, string>({
      query(code) {
        return {
          url: `${getBasename()}/Fundraiser/CodeValidity?code=${code}`,
          method: 'POST',
        };
      },
    }),
    login: builder.mutation<User, { userName: string, password: string, rememberMe: boolean }>({
      query(body) {
        return {
          url: `${getBasename()}/Account/Login`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['User', 'Player', 'UNAUTHORIZED'],
    }),
    logout: builder.mutation<User, void>({
      query() {
        return {
          url: `${getBasename()}/Account/LogOff`,
          method: 'POST',
        };
      },
      invalidatesTags: ['User', 'Player', 'UNAUTHORIZED'],
    }),
    donate: builder.mutation<DonationResult, DonationRequest>({
      query(body) {
        return {
          url: `${getBasename()}/Donation/Pay`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Fan', 'Detail', 'Player'],
    }),
    addMessage: builder.mutation<AddMessageResult, AddMessage>({
      query(body) {
        return {
          url: `${getBasename()}/Donation/Message`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Message'],
    }),
    savePlayerContact: builder.mutation<PlayerContact, SavePlayerContact>({
      query(body) {
        return {
          url: `${getBasename()}/PlayerContact/Contact${body.id ? `/${body.id}` : ''}`,
          method: body.id ? 'PUT' : 'POST',
          body,
        };
      },
      invalidatesTags: ['PlayerContact'],
    }),
    importOldContacts: builder.mutation<number, void>({
      query(body) {
        return {
          url: `${getBasename()}/PlayerContact/ImportOldForce`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['PlayerContact', 'Player'],
    }),
    tapContactFinale: builder.mutation<void, void>({
      query() {
        return {
          url: `${getBasename()}/PlayerContact/TapContactFinale`,
          method: 'POST',
        };
      },
    }),
    savePledge: builder.mutation<PlayerContact, SavePlayerContact>({
      query(body) {
        return {
          url: `${getBasename()}/PlayerContact/Pledge/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['PlayerContact'],
    }),
    deletePlayerContact: builder.mutation<void, number>({
      query(id) {
        return {
          url: `${getBasename()}/PlayerContact/Contact/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['PlayerContact'],
    }),
    signUp: builder.mutation<GenericResult, SignUp>({
      query(data) {
        const formData = new FormData();
        formData.append('code', data.code);
        formData.append('email', data.email);
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('password', data.password);
        formData.append('confirmPassword', data.confirmPassword);
        formData.append('phoneNumber', data.phoneNumber);
        formData.append('cropOptions', data.cropOptions);
        if (data.photoFile) {
          formData.append('photoFile', data.photoFile);
        }
        if (data.player) {
          formData.append('player', data.player.toString());
        }
        return {
          url: `${getBasename()}/Account/Register`,
          method: 'POST',
          credentials: 'include',
          body: formData,
        };
      },
      invalidatesTags: ['User'],
    }),
    sendForgotPasswordLink: builder.mutation<GenericResult, ForgotPasswordRequest>({
      query(body) {
        return {
          url: `${getBasename()}/Account/ForgotPassword`,
          method: 'PUT',
          body,
        };
      },
    }),
    confirmForgotPassword: builder.mutation<GenericResult, ForgotPasswordRequest>({
      query(body) {
        return {
          url: `${getBasename()}/Account/ForgotPasswordConfirm`,
          method: 'PUT',
          body,
        };
      },
    }),
    textCode: builder.mutation<GenericResult, TextCode>({
      query(body) {
        return {
          url: `${getBasename()}/Account/LoginPhone`,
          method: 'PUT',
          body,
        };
      },
    }),
    confirmTextCode: builder.mutation<GenericResult, TextCode>({
      query(body) {
        return {
          url: `${getBasename()}/Account/LoginPhoneVerify`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation<GenericResult, ChangePassword>({
      query(body) {
        return {
          url: `${getBasename()}/Manage/Password`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateProfile: builder.mutation<GenericResult, Profile>({
      query(body) {
        return {
          url: `${getBasename()}/Manage/Profile`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['User', 'Player'],
    }),
    updatePhoto: builder.mutation<GenericResult, Photo>({
      query(data) {
        const formData = new FormData();
        formData.append('cropOptions', data.cropOptions);
        formData.append('photoFile', data.photoFile);
        return {
          url: `${getBasename()}/Manage/Photo`,
          method: 'PUT',
          credentials: 'include',
          body: formData,
        };
      },
      invalidatesTags: ['Player'],
    }),
    saveSubscriptionSettings: builder.mutation<GenericResult, SubscriptionSettingsEntity>({
      query(body) {
        return {
          url: `${getBasename()}/Fundraiser/SubscriptionSettings`,
          method: 'POST',
          body,
        };
      },
    }),
    getSubscriptionSettings: builder.query<SubscriptionSettings, SubscriptionSettingsId>({
      query: (data) => (
        `${getBasename()}/Fundraiser/SubscriptionSettings?PublicId=${data.publicId}&SubscriptionToken=${data.subscriptionToken}`
      ),
    }),

    // coach:
    getCoachTeams: builder.query<CoachTeam[], void>({
      query: () => (`${getBasename()}/Coach/Team`),
    }),
    getCoachUnconfirmedDonations: builder.query<UnconfirmedDonation[], void>({
      query: () => (`${getBasename()}/Coach/UnconfirmedDonation`),
      providesTags: ['UnconfirmedDonations'],
    }),
    getCoachDonationStatusSummary: builder.query<DonationStatusSummary, void>({
      query: () => (`${getBasename()}/Coach/PledgeDonationSummary`),
      providesTags: ['CoachSummary'],
    }),
    getCoachDonationMethodSummary: builder.query<DonationMethodSummary, void>({
      query: () => (`${getBasename()}/Coach/DonationSummary`),
      providesTags: ['CoachSummary'],
    }),
    getCoachDonations: builder.query<CoachDonation[], CoachFilter>({
      query: (filter) => (`${getBasename()}/Coach/Donation${filter.teamId > 0 ? `?teamId=${filter.teamId}` : ''}`),
      providesTags: ['CoachDonation'],
      keepUnusedDataFor: 3,
    }),
    getCoachPledges: builder.query<CoachPledge[], CoachFilter>({
      query: (filter) => (`${getBasename()}/Coach/Pledges${filter.teamId > 0 ? `?teamId=${filter.teamId}` : ''}`),
      providesTags: ['CoachPledge'],
      keepUnusedDataFor: 3,
    }),
    getCoachContacts: builder.query<CoachPlayerContact[], CoachFilter>({
      query: (filter) => (`${getBasename()}/Coach/Contact${filter.teamId > 0 ? `?teamId=${filter.teamId}` : ''}`),
      providesTags: ['CoachContact', 'CoachPledge'],
      keepUnusedDataFor: 3,
    }),
    getCoachTotals: builder.query<CoachDonationSummary[], CoachFilter>({
      query: (filter) => (`${getBasename()}/Coach/Totals${filter.teamId > 0 ? `?teamId=${filter.teamId}` : ''}`),
      keepUnusedDataFor: 3,
    }),
    getPledgeSummary: builder.query<CoachPledgeSummary, void>({
      query: () => (`${getBasename()}/Coach/PledgeSummary`),
      providesTags: ['CoachPledge'],
    }),
    getCoachContactSummary: builder.query<CoachPlayerContactSummary, void>({
      query: () => (`${getBasename()}/Coach/PlayerContactSummary`),
      providesTags: ['CoachContact'],
    }),
    getCoachContact: builder.query<CoachContact, string>({
      query: (code) => (`${getBasename()}/Coach/SearchPledge?FullUniqueCode=${code}`),
      providesTags: ['CoachContact', 'CoachPledge'],
      keepUnusedDataFor: 3,
    }),
    getCoachContactStatuses: builder.query<CoachContactState[], void>({
      query: () => (`${getBasename()}/Coach/ContactState`),
    }),
    coachReconicleCheck: builder.mutation<GenericResult, ReconcileCheck>({
      query(body) {
        return {
          url: `${getBasename()}/Coach/ConfirmDonation`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UnconfirmedDonations', 'CoachSummary', 'CoachDonation'],
    }),
    coachUpdatePledge: builder.mutation<GenericResult, CoachUpdatePledgeRequest>({
      query(body) {
        return {
          url: `${getBasename()}/Coach/Pledge`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CoachPledge', 'CoachContact'],
    }),
    saveCoachPlayerContact: builder.mutation<GenericResult, SaveCoachPlayerContact>({
      query(body) {
        return {
          url: `${getBasename()}/Coach/Contact`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CoachContact'],
    }),
    getCoachPlayerPledge: builder.query<CoachPledge, number>({
      query: (id) => (`${getBasename()}/Coach/Pledge/${id}`),
      providesTags: ['CoachPledge', 'CoachContact'],
    }),
    coachUpdateEmail: builder.mutation<GenericResult & { reminderSent: boolean }, UpdateEmail>({
      query(body) {
        return {
          url: `${getBasename()}/Coach/UpdateContactEmail`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CoachPledge'],
    }),
  }),
});

export const {
  useGetEnvAliasQuery,
  useGetFansQuery,
  useGetMessagesQuery,
  useGetDetailsQuery,
  useGetMediaQuery,
  useGetDonorQuery,
  useGetUserQuery,
  useLoginMutation,
  useLazyGetUserQuery,
  useLogoutMutation,
  useGetPlayersNamesQuery,
  useGetPlayerQuery,
  useDonateMutation,
  useAddMessageMutation,
  useCodeValidityMutation,
  useSignUpMutation,
  useGetPlayerContactsQuery,
  useGetPlayerDonationsQuery,
  useGetPlayerSummaryQuery,
  useGetPlayerContactQuery,
  useSavePlayerContactMutation,
  useDeletePlayerContactMutation,
  useGetCurrentPlayerQuery,
  useGetAmountsQuery,
  useSavePledgeMutation,
  useSendForgotPasswordLinkMutation,
  useConfirmForgotPasswordMutation,
  useTextCodeMutation,
  useConfirmTextCodeMutation,
  useRefreshAntiForgeryTokenMutation,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useUpdatePhotoMutation,
  useGetBoardScoresQuery,
  useGetCoachDonationStatusSummaryQuery,
  useGetCoachDonationMethodSummaryQuery,
  useGetCoachUnconfirmedDonationsQuery,
  useCoachReconicleCheckMutation,
  useGetCoachTeamsQuery,
  useGetCoachDonationsQuery,
  useGetCoachTotalsQuery,
  useGetPledgeSummaryQuery,
  useGetCoachContactQuery,
  useGetCoachPledgesQuery,
  useCoachUpdateEmailMutation,
  useGetCoachContactSummaryQuery,
  useGetCoachContactsQuery,
  useGetCoachContactStatusesQuery,
  useGetCoachPlayerPledgeQuery,
  useCoachUpdatePledgeMutation,
  useSaveCoachPlayerContactMutation,
  useImportOldContactsMutation,
  useGetPlayerRenewedStateQuery,
  useGetSubscriptionSettingsQuery,
  useSaveSubscriptionSettingsMutation,
  useGetPlayerContactsToRemindQuery,
  useTapContactFinaleMutation,
  useGetLiveDashboardQuery,
} = apiSlice;
