import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from '../../context/AuthProvider';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#46828a',
    },
    secondary: {
      main: '#51a051',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: 'black',
          background: 'linear-gradient(180deg, rgba(156,208,214,1) 0%, rgba(156,208,214,1) 88%, rgba(109,152,155,1) 100%)',
          '&:hover': {
            background: 'linear-gradient(180deg, rgba(156,208,214,0.8) 0%, rgba(156,208,214,0.8) 88%, rgba(109,152,155,0.8) 100%)',
          },
        },
      },
    },
  },
});

export function GlobalLayout(): JSX.Element {
  return (
    <AuthProvider>
      <ThemeProvider theme={mainTheme}>
        <Outlet />
      </ThemeProvider>
    </AuthProvider>
  );
}
