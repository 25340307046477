/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import logo from '../../assets/images/logo2.png';

export function Footer(): JSX.Element {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
        fontSize="small"
        whiteSpace="nowrap"
      >
        <Link color="grey.500" href="https://www.fan-pledge.com/privacy-policy/">Privacy Policy</Link>
        <Link color="grey.500" href="https://www.fan-pledge.com/contact/">Contact Us</Link>
        <Link color="grey.500" href="https://www.fan-pledge.com/">About Us</Link>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: '0.5rem',
        }}
      >
        <Typography variant="caption">
          <span>Powered by</span>
        </Typography>
        <img src={logo} alt="Fan-Pledge.Com" style={{ width: '120px', marginLeft: '0.5rem' }} />
        <Typography variant="caption">
          &copy;
          {' '}
          {(new Date().getFullYear())}
          {' '}
          Fan-Pledge LLC
        </Typography>
      </Box>
    </>
  );
}
