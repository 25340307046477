import { useLocation, Navigate } from 'react-router-dom';
import useAuth from '../context/AuthProvider';
import { UserRole } from '../features/user/domain';

type RequireAuthProps = {
  children: JSX.Element;
  roles?: UserRole[];
};

export function RequireAuth({ children, roles }: RequireAuthProps): JSX.Element {
  const auth = useAuth();
  const location = useLocation();

  console.log(auth);

  if (!auth.user || auth.user.roleName === UserRole.Guest || (roles && !roles.includes(auth.user.roleName))) {
    return <Navigate to="/Account/Login" state={{ from: location }} replace />;
  }

  return children;
}
